import truncate from "lodash/truncate";
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Popup } from "../../lib/custom-ui";

import Paths from "../../constants/Paths";
import { ActivityTypes } from "../../constants/Enums";
import User from "../User";

import styles from "./NotificationsStep.module.scss";

const NotificationsStep = React.memo(({ items, onDelete, onClose }) => {
  const [t] = useTranslation();

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete]
  );

  const handleDeleteAll = useCallback(() => {
    items.forEach((item) => {
      onDelete(item.id);
    });
  }, [items, onDelete]);

  useEffect(() => {
    console.log("Items", items);
  }, [items]);

  const renderItemContent = useCallback(
    ({ activity, card }) => {
      switch (activity.type) {
        case ActivityTypes.MOVE_CARD:
          return (
            <>
              {activity.user.name} movió{" "}
              <Link to={Paths.CARDS.replace(":id", card.id)} onClick={onClose}>
                {card.name}
              </Link>{" "}
              de {activity.data.fromList.name} a {activity.data.toList.name}
            </>
          );

        case ActivityTypes.COMMENT_CARD: {
          const commentText = truncate(activity.data.text);
          return (
            <Trans
              i18nKey="common.userLeftNewCommentToCard"
              values={{
                user: activity.user.name,
                comment: commentText,
                card: card.name,
              }}
            >
              {activity.user.name}
              {` left a new comment «${commentText}» to `}
              <Link to={Paths.CARDS.replace(":id", card.id)} onClick={onClose}>
                {card.name}
              </Link>
            </Trans>
          );
        }

        case ActivityTypes.MEMBER_JOIN_CARD:
          return (
            <Trans
              i18nKey="common.userJoinedCard"
              values={{ user: activity.user.name, card: card.name }}
            >
              {activity.user.name} {" te añadió a la tarjeta: "}
              <Link to={Paths.CARDS.replace(":id", card.id)} onClick={onClose}>
                {card.name}
              </Link>
            </Trans>
          );

        case ActivityTypes.REPLY_COMMENT:
          return (
            <Trans
              i18nKey="common.userJoinedCard"
              values={{ user: activity.user.name, card: card.name }}
            >
              {activity.user.name} {" te añadió a la tarjeta: "}
              <Link to={Paths.CARDS.replace(":id", card.id)} onClick={onClose}>
                {card.name}
              </Link>
            </Trans>
          );

        case ActivityTypes.MENTION_USER: {
          const mentionedUser = activity.data.mentionedUser
            ? activity.data.mentionedUser.charAt(0).toUpperCase() +
              activity.data.mentionedUser.slice(1)
            : "";
          return (
            <Trans
              i18nKey="common.userMentionedYouInComment"
              values={{ user: activity.user.name, card: card.name }}
            >
              {activity.user.name} {" mencionó a "}
              <b>{mentionedUser}</b> {" en un comentario en "}
              <Link to={Paths.CARDS.replace(":id", card.id)} onClick={onClose}>
                {card.name}
              </Link>
            </Trans>
          );
        }

        default:
          return null;
      }
    },
    [onClose]
  );

  return (
    <>
      <Popup.Header>
        {t("common.notifications", { context: "title" })}
      </Popup.Header>
      <Popup.Content>
        {items.length > 0 ? (
          <div className={styles.wrapper}>
            {items.filter((item) => !item.isDeleted && !item.isRead).length > 0 && (
              <Button
                type="button"
                icon="trash alternate outline"
                content={t("action.deleteNotifications")}
                onClick={handleDeleteAll}
                className={styles.deleteAllButton}
              />
            )}
            {items
              .filter((item) => !item.isDeleted && !item.isRead)
              .map((item) => (
                <div
                  key={item.id}
                  className={`${styles.item} ${item.isRead ? styles.itemRead : ""}`}
                >
                  {item.card && item.activity ? (
                    <>
                      <User
                        name={item.activity.user.name}
                        avatarUrl={item.activity.user.avatarUrl}
                        size="large"
                      />
                      <span className={styles.itemContent}>
                        {renderItemContent(item)}
                      </span>
                    </>
                  ) : (
                    <div className={styles.itemDeleted}>
                      {t("common.cardOrActionAreDeleted")}
                    </div>
                  )}
                  <Button
                    type="button"
                    icon="trash alternate outline"
                    className={styles.itemButton}
                    onClick={() => handleDelete(item.id)}
                  />
                </div>
              ))}
          </div>
        ) : (
          t("common.noUnreadNotifications")
        )}
      </Popup.Content>
    </>
  );
});

NotificationsStep.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationsStep;
