import React, { useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Draggable } from "react-beautiful-dnd";
import { Button, Checkbox, Icon } from "semantic-ui-react";
import { usePopup } from "../../../lib/popup";

import NameEdit from "./NameEdit";
import ActionsStep from "./ActionsStep";
import Linkify from "../../Linkify";

import styles from "./Item.module.scss";

const Item = React.memo(
  ({
    id,
    index,
    name,
    isCompleted,
    isPersisted,
    canEdit,
    onUpdate,
    onDelete,
  }) => {
    const nameEdit = useRef(null);

    const handleClick = useCallback(() => {
      if (isPersisted && canEdit) {
        nameEdit.current.open();
      }
    }, [isPersisted, canEdit]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate]
    );

    const handleToggleChange = useCallback(() => {
      onUpdate({
        isCompleted: !isCompleted,
      });
    }, [isCompleted, onUpdate]);

    const handleNameEdit = useCallback(() => {
      nameEdit.current.open();
    }, []);




    const ActionsPopup = usePopup(ActionsStep);

    return (
      <Draggable
        draggableId={id}
        index={index}
        isDragDisabled={!isPersisted || !canEdit}
      >
        {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
          const contentNode = (
            <div
              {...draggableProps}
              {...dragHandleProps}
              ref={innerRef}
              className={styles.wrapper}
            >
              <span className={styles.checkboxWrapper}>
                <Checkbox
                  checked={isCompleted}
                  disabled={!isPersisted || !canEdit}
                  className={styles.checkbox}
                  onChange={handleToggleChange}
                />
              </span>
              <NameEdit
                ref={nameEdit}
                defaultValue={name}
                onUpdate={handleNameUpdate}
              >
                <div className={classNames(canEdit && styles.contentHoverable)}>
                  <span
                    className={classNames(
                      styles.text,
                      canEdit && styles.textEditable
                    )}
                    onClick={handleClick}
                  >
                    <span
                      style={{ whiteSpace: "pre-wrap" }}
                      className={classNames(
                        styles.task,
                        isCompleted && styles.taskCompleted
                      )}
                    >
                      <Linkify linkStopPropagation>{name}</Linkify>
                    </span>
                  </span>
                  {isPersisted && canEdit && (
                    <ActionsPopup
                      onNameEdit={handleNameUpdate}
                      onDelete={onDelete}
                    >
                      <Button
                        className={classNames(styles.button, styles.target)}
                      >
                        <Icon
                          fitted
                          name="pencil"
                          size="small"
                          style={{ color: "white" }}
                        />
                      </Button>
                    </ActionsPopup>
                  )}
                </div>
              </NameEdit>
            </div>
          );

          return isDragging
            ? ReactDOM.createPortal(contentNode, document.body)
            : contentNode;
        }}
      </Draggable>
    );
  }
);

Item.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Item;
